/**
 * Main JS
 */
(function($) {

  let isSticky = false,
      activeSectionId = null;
  let stick_nav_elems = {
    'nav': $('nav#priorities-side-nav'),
    'mainContainer': $('div.bottom-section'),
    'mainContent': $('.priority-items'),
    'mainContentSections': $('.priority-items > div'),
    'sectionOffsets': null,
  };

  let didScroll,
      lastScrollTop = 0,
      delta = 4,
      header = $('header.header'),
      navbarHeight = header.outerHeight();


  /**
   * Add aria labels to buttons in carousels
   */
  function set_carousel_aria_labels () {

    const carousels = $('.owl-carousel');
    if (carousels.length) {
      set_custom_owl_nav_aria();
      set_owl_dots_aria();
    }

  }//end set_carousel_aria_labels


  function set_owl_dots_aria () {

    const owl_dots = $('div.owl-dots');
    if (owl_dots.length) {
      owl_dots.each(function(){

        let $this = $(this);
        if ($this.find('button').length) {
          let buttons = $this.find('button'),
              counter = 1;
          buttons.each(function(){
            $(this).attr('aria-label', 'Item '+counter);
            counter++;
          })
        }

      });
    }

  }//end set_custom_owl_dots_aria


  function set_custom_owl_nav_aria () {
    if ($('.owl-nav-container').length) {
      $('.owl-nav-container').each(function(){

        let $this = $(this);
        $this.find('button.owl-next').attr('aria-label', 'Next').attr('role', 'button');
        $this.find('button.owl-prev').attr('aria-label', 'Previous').attr('role', 'button');

      });
    }
    }//end set_custom_owl_nav_aria


  // Debounce function to limit the scroll event frequency
  function debounce(func, wait) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    };
  }//end debounce


  /**
   * Align the headings and content within each post
   */
  function align_posts () {

    const cards = $('.post-item-outer:not(.cpt-visibility-hidden):not(.carousel-post-item)');
    if (cards.length) {
      let title_min_h = 0;
      let details_min_h = 0;
      cards.each(function(){
        let h3 = $(this).find('h3');
        let details = $(this).find('.details-wrap');

        h3.css('height', 'auto');
        details.css('height', 'auto');

        let h3_h = h3.height();
        let details_h = details.height();
        if (parseInt(h3_h) > title_min_h) {
          title_min_h = h3_h;
        }
        if (parseInt(details_h) > details_min_h) {
          details_min_h = details_h;
        }
      });

      cards.find('h3').height(title_min_h);
      cards.find('.details-wrap').height(details_min_h);
    }//end if cards len

  }//end alignPosts


  /**
   * Align the headings and content within each post
   */
  function align_carousel_posts () {

    const cards = $('.post-item-outer.carousel-post-item');
    if (cards.length) {
      let title_min_h = 0;
      cards.each(function(){
        let h3 = $(this).find('h3');
        h3.css('height', 'auto');
        let h3_h = h3.height();
        if (parseInt(h3_h) > title_min_h) {
          title_min_h = h3_h;
        }
      });

      cards.find('h3').height(title_min_h);
    }//end if cards len

  }//end align_carousel_posts


  function post_cols_loaded () {

    if ($('.post-item-outer:not(.cpt-visibility-hidden)').length) {
      $('.post-item-outer:not(.cpt-visibility-hidden) img').on('load', function(){

        let total_imgs = $('.post-item-outer:not(.cpt-visibility-hidden) img').length,
            loaded_imgs = $('.post-item-outer:not(.cpt-visibility-hidden) img').filter(function(){
              return this.complete;
            }).length;

        if (loaded_imgs === total_imgs) {
          align_posts();
        }

      });
    }

  }//end post_cols_loaded


  function img_cols_loaded () {

    if ($('.img-col').length) {
      $('.img-col img').on('load', function(){

        let total_imgs = $('.img-col img').length,
            loaded_imgs = $('.img-col img').filter(function(){
              return this.complete;
            }).length;

        if (loaded_imgs === total_imgs) {
          align_img_cols();
        }

      });
    }

  }//end img_cols_loaded


  function align_img_cols () {

    if ($('.img-col').length) {

      const cards = $('.img-col');
      if (cards.length) {
        let title_min_h = 0;
        let content_min_h = 0;
        cards.each(function(){
          let h3 = $(this).find('h3');
          let content = $(this).find('.content-inner');

          h3.css('height', 'auto');
          content.css('height', 'auto');

          let h3_h = h3.height();
          let content_h = content.height();
          if (parseInt(h3_h) > title_min_h) {
            title_min_h = h3_h;
          }
          if (parseInt(content_h) > content_min_h) {
            content_min_h = content_h;
          }
        });

        cards.find('h3').height(title_min_h);
        cards.find('.content-inner').height(content_min_h);
      }//end if cards len


    }//end col len

  }//end align_img_cols


  function align_collapsible_cols () {

    if ($('.collapsible-columns-section div.col-wrap').length) {

      const cols = $('.collapsible-columns-section div.col-wrap');
      if (cols.length) {
        let title_min_h = 0;
        cols.each(function(){
          let $this = $(this);
          $this.find('button, a.link-item').css('height', 'auto');
          let hh = $this.find('button, a.link-item').height();
          if (parseInt(hh) > title_min_h) {
            title_min_h = hh;
          }
        });

        cols.find('button, a.link-item').height(title_min_h);
      }//end if cards len


    }//end col len

  }//end align_collapsible_cols



  /**
   * Stick the priorities nav on scroll
   */
  function stick_navigation () {

    let distanceFromTop = stick_nav_elems.mainContainer.offset().top;
    const scrollTop = $(window).scrollTop();
    const navHeight = stick_nav_elems.nav.outerHeight();

    const header = $('header.header');
    let header_h = (header.outerHeight() - $('.header-top-nav').outerHeight());
    if (header.hasClass('nav-down')) {
      header_h = header.outerHeight();
      distanceFromTop -= $('.header-top-nav').outerHeight();
    }

    let sticky_top = header_h - 3;
    if (stick_nav_elems.nav.hasClass('sticky')) {
      stick_nav_elems.nav.css('top', sticky_top);
    }



    if (scrollTop >= distanceFromTop) {

      //Set a max width to prevent layout shift:
      let sidebar_w = stick_nav_elems.nav.parent('div').width();
      stick_nav_elems.nav.css('max-width', sidebar_w);

      if (!isSticky) {
        stick_nav_elems.nav.addClass('sticky').css('top', sticky_top);
        stick_nav_elems.nav.removeClass('sticky-bottom');
        isSticky = true;
      }
    } else {
      stick_nav_elems.nav.css('max-width', 'none');
      if (isSticky) {
        stick_nav_elems.nav.removeClass('sticky').css('top', 'auto');
        stick_nav_elems.nav.removeClass('sticky-bottom');
        isSticky = false;
      }
    }

    stick_nav_elems.mainContentSections.each(function() {
      const section = $(this);
      const sectionId = section.find('span.priority-section-target').attr('id');
      const offsetTop = Math.floor(section.find('span.priority-section-target').offset().top - (header_h)); //2 === make sure there's no overlap between sections

      if (scrollTop >= offsetTop) {
        activeSectionId = sectionId;
        return true;
      }
    });

    stick_nav_elems.nav.find('a').removeClass('active');
    stick_nav_elems.nav.find('a').attr('aria-current', 'false');
    let active_item = stick_nav_elems.nav.find('a[href="#' + activeSectionId + '"]');


    if (active_item.length) {
      active_item.addClass('active');
      active_item.attr('aria-current', 'true');
    }//end active item len



    const mainContentBottom = distanceFromTop + stick_nav_elems.mainContent.outerHeight();
    if (navHeight + scrollTop >= mainContentBottom) {
      stick_nav_elems.nav.removeClass('sticky').css('top', 'auto');
      stick_nav_elems.nav.addClass('sticky-bottom');
      isSticky = false;
    } else {
      stick_nav_elems.nav.removeClass('sticky-bottom');
    }

  }//end stick_navigation


  /**
   * Load the map section map
   */
  function load_map () {

    let map_path = $('section.map-section').data('map-file');
    fetch(map_path)
      .then(response => response.text())
      .then(svgData => {
        $('section.map-section div.map-wrap').html(svgData);

        //Set global map scroll position to center:
        set_map_scroll_position();
      })
      .catch(error => console.error('Error loading SVG:', error));

  }//end load_map


  /**
   * Set active links in the nav for page section links
   */
  function toggle_subnav_active () {

    function is_in_view (section) {

      let header_h = (header.outerHeight() - $('.header-top-nav').outerHeight());
      let sectionTop = section.offset().top - header_h,
        sectionBottom = sectionTop + section.outerHeight(),
        viewportTop = $(window).scrollTop();

      //If we're scrolling up, factor in the header top nav height
      if (sectionTop < lastScrollTop) {
        sectionTop = parseInt(section.offset().top) - header.outerHeight();
      }

      if (sectionTop <= viewportTop && sectionBottom > viewportTop) {
        return true;
      } else {
        return false;
      }

    }//end is_in_view

    const section_ids = $('span.section-target').filter(function(){
      return $(this).attr('id');
    });

    if (section_ids.length) {

      section_ids.each(function(){

        const $this = $(this),
              this_id = $this.attr('id'),
              parent = $this.parent('section');

        let nav_item = $('li.current-menu-item.nav-item > a.dropdown-item').filter(function(){
          return $(this).attr('href').endsWith('#'+this_id);
        });

        if (nav_item && is_in_view(parent)) {
          nav_item.addClass('subnav-active');
        } else {
          nav_item.removeClass('subnav-active');
        }

      });

    }

  }//end toggle_subnav_active


  /**
   * Check the scroll position and animate the top bar out if scrolled.
   */
  function hasScrolled() {
    let st = $(this).scrollTop();

    if (lastScrollTop > 0) {

      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      //Hide the search if it is showing
      $('div#ampc-header-search').removeClass('active');

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight) {
        // Scroll Down
        header.removeClass('nav-down').addClass('nav-up');
      } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          header.removeClass('nav-up').addClass('nav-down');
        }
      }

      toggle_subnav_active();

    }//end last scroll > 0


    lastScrollTop = st;
  }//end hasScrolled


  //Cookie management for popups:
  function set_cookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }//end set_cookie
  function get_cookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }//end get_cookie


  function set_map_scroll_position () {

    const $container = $('.map-wrap');
    const $wideImage = $('.map-wrap svg');

    // Calculate the center position of the wide image
    const centerPosition = ($wideImage[0].scrollWidth - $container.width()) / 2;

    // Set the initial scroll position to display the center of the image
    $container.scrollLeft(centerPosition);

  }//end set_map_scroll_position


  /**
   * Set the scroll to target offset based on the URL
   */
  function check_url_for_in_page_link () {

    let currentURL = window.location.href,
        url = new URL(currentURL),
        fragment = url.hash;

    if (fragment && fragment !== '#') {

      //Section padding + (header height - top-nav height)
      let hh = ($('header.header').outerHeight() - $('.header-top-nav').outerHeight());
      let offset = '-'+(73 + hh)+'px';
      let is_priorities = false;

      if ($(window).width() <= 767) {//mobile
        offset = '-121px';

        //Special case for priorities section
        if ($(fragment).length && typeof $(fragment).data('ost-mob') !== 'undefined') {
          is_priorities = true;
          $(fragment).css('cssText', 'top: -24px !important;');
          setTimeout(function () {
            $(fragment).css('cssText', 'top: 50px !important;');
          }, 2000);
        }

      } else if ($(window).width() > 767 && $(window).width() < 992) {//tab
        offset = '-151px';

        //Special case for priorities section
        if ($(fragment).length && typeof $(fragment).data('ost-tab') !== 'undefined') {
          is_priorities = true;
          $(fragment).css('cssText', 'top: 0px !important;');
          setTimeout(function () {
            $(fragment).css('cssText', 'top: 74px !important;');
          }, 2000);
        }
      }

      if (!is_priorities) {
        $('span.section-target').css('top', offset);
        //Revert to in page offset after scroll:
        setTimeout(function () {
          offset = ($(window).width() <= 767) ? '-49px' : '-73px';
          $('span.section-target').css('top', offset);
        }, 2000);
      }

    }

  }//end check_url_for_in_page_link


  /**
   * After page load, reset the section target offsets for accurate in page scrolling and navigation
   */
  function reset_section_targets_offset () {
    setTimeout(function () {
      let targets = $('span.section-target');
      if (targets.length) {
        targets.css('top', '0px');
      }//end targets.len

    }, 1500);
  }//end reset_section_targets_offset


  /**
   * Get the scroll offset of a section for in page navigation
   * @param target
   * @returns {number}
   */
  function get_section_scroll_to_offset (target) {

    const header = $('header.header');
    let header_h = (header.outerHeight() - $('.header-top-nav').outerHeight());
    let offset = parseInt($(target).offset().top) - header_h;

    //If we're scrolling up, factor in the header top nav height
    if (offset < lastScrollTop) {
      offset = parseInt($(target).offset().top) - header.outerHeight();
    }

    //Add a bit of a buffer to make sure we're within the section - but not for the priorities inner sections
    if (!$(target).hasClass('priority-section-target')) {
      offset += 5;
    }

    return offset;

  }//end get_section_scroll_to_offset


  //Toggle tabindex for active owl-items buttons for keyboard nav
  function set_carousel_buttons_tabindex (this_carousel) {

    // Retrieve all owl-item elements within this carousel
    let owl_items = this_carousel.$element.find('.owl-item');

    if (owl_items.length) {
      owl_items.each(function(){

        let $this_item = $(this);
        if ($this_item.hasClass('active')) {
          $this_item.find('.col-carousel-button').attr('tabindex', '0');
        } else {
          $this_item.find('.col-carousel-button').attr('tabindex', '-1');
        }

      });
    }


  }//end set_carousel_cols_buttons_tabindex


  $(function() {


    //Section scroll-to targets have a negative offset on page load. The offset is the header height.
    //This is for scrolling to a section on page load.
    //Once the page has loaded and we've scrolled to this section, we reset the target offset for accurate in-page scrolling
    reset_section_targets_offset();


     //Align image cols:
    align_img_cols();
    img_cols_loaded();

    //Align collapsible cols:
    align_collapsible_cols();


    //Align posts
    if (!$('body').hasClass('search')) {
      align_posts();
      post_cols_loaded();
    }


    //Recalculate and set alignment for cards on window resize
    $(window).on('resize', function(){
      align_img_cols();
      align_collapsible_cols();
      align_posts();
      if (stick_nav_elems.nav.length) {
        stick_navigation();
      }
    });

    //Check to see if the user has made a language selection.
    var ck = get_cookie('selected-lang');
    if (ck === null && ck !== 'true') {
      $('.language-popup').removeClass('inactive removing');
      $('.language-popup a.active').focus();
    }

    //OnScroll - do sticky nav
    if (stick_nav_elems.nav.length) {
      const debounce_sticky_nav = debounce(stick_navigation, 5);
      $(window).on('scroll', debounce_sticky_nav);
    }//end if sticky nav len


    //Set did scroll if we have a hash in the URL. This will trigger the header top bar hidden
    if (window.location.hash) {
      header.removeClass('nav-down').addClass('nav-up');
      didScroll = true;
    }

    $(window).scroll(function (event) {
      didScroll = true;
    });

    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 50);


    //Page scroll
    $("a.page-scroll, a.scroll-to, li.page-scroll.current-menu-item > a").bind("click", function (e) {

      let target = $(this).attr("href");

      //Check if this is an in-page link, or scrolling link:
      let is_page_link = false;
      if (target && !target.startsWith('#')) {
        let url = new URL(target, window.location.href);
        target = decodeURIComponent(url.hash);
        is_page_link = true;
      }

      //If target is #next get next section after the hero:
      if (target === '#next') {
        target = $('.hero-section').next();
      }

      if (!target.length || !$(target).length) {
        return false;
      }

      //Trigger the click on the mobile nav to close it before scrolling:
      if (is_page_link && $(window).width() <= 991 && !$('button.mobile-menu-toggle').hasClass('collapsed')) {
        $('button.mobile-menu-toggle').trigger('click');

        //Wait to let the header collapse before calculating the offset and scrolling
        setTimeout(function () {
          $("html, body").scrollTop(get_section_scroll_to_offset(target));
        }, 550);

      } else {
        $("html, body").scrollTop(get_section_scroll_to_offset(target));
      }

      e.preventDefault();

    });


    //Init video lightbox:
    if ($('.ampc-video-lightbox').length && typeof GLightbox !== 'undefined') {

      $('.ampc-video-lightbox').each(function(){


        let lb_html = `<div id="glightbox-body" class="glightbox-container glightbox-clean" tabindex="-1" role="dialog" aria-hidden="false">
            <div class="gloader visible"></div>
            <div class="goverlay"></div>
            <div class="gcontainer">
            <div id="glightbox-slider" class="gslider"></div>
            <button class="gnext gbtn" tabindex="-1" aria-label="Next" style="display: none;">{nextSVG}</button>
            <button class="gprev gbtn" tabindex="-1" aria-label="Previous" style="display: none;">{prevSVG}</button>
            <button class="gclose gbtn" tabindex="0" aria-label="Close">{closeSVG}</button>
        </div>
        </div>`;

        let $this = $(this);
        var lightboxInlineIframe = GLightbox({
          lightboxHTML: lb_html,
          selector: '#'+$this.attr('id'),
          autoplayVideos: false,
          autofocusVideos: true,
          type: 'video',
          plyr: {
            hideControls: false,
            keyboard:{
              focused: true,
              global: true
            }
          }
        });

        lightboxInlineIframe.on('open', function(){
          setTimeout(function () {
            $('button.plyr__control.plyr__control--overlaid').attr('tabindex', 0).focus();
            $('.plyr--video .plyr__controls').attr('tabindex', 0).find('button').attr('tabindex', 0);
          }, 500);
        });

        lightboxInlineIframe.on('slide_before_load', function(){
          setTimeout(function () {
            $('button.plyr__control.plyr__control--overlaid').attr('tabindex', 0).focus();
            $('.plyr--video .plyr__controls').attr('tabindex', 0).find('button').attr('tabindex', 0);
          }, 500);
        });

        lightboxInlineIframe.on('slide_after_load', function(){
          setTimeout(function () {
            $('button.plyr__control.plyr__control--overlaid').attr('tabindex', 0).focus();
            $('.plyr--video .plyr__controls').attr('tabindex', 0).find('button').attr('tabindex', 0);
          }, 500);
        });

      });

    }//end if $('.ampc-video-lightbox')


    //Align posts when shown via collapse:
    $('.posts-section.collapse').on('shown.bs.collapse', function(){
      let $this = $(this);
      $this.find('.post-item-outer.cpt-visibility-hidden').removeClass('cpt-visibility-hidden');
      align_posts();
      post_cols_loaded();
    });


    //Check if we have a map section on this page, if we do, the load the map:
    if ($('section.map-section .map-wrap').length) {
      load_map();
    }


    //Post card carousel
    if ($('.posts-carousel').length) {

      let carousels = $('.posts-carousel'),
          max_items = 3;

      $('.posts-carousel div.post-item-wrap a.btn').addClass('col-carousel-button');

      carousels.each(function(){

        let $this = $(this),
            items = $this.data('items-count'),
            nav_container = $this.data('nav-container'),
            dots_container = $this.data('dots-container');

        if (items > max_items) {
          items = max_items;
        }

        $this.owlCarousel({
          items: items,
          dots: false,
          nav: true,
          navContainer: nav_container,
          dotsContainer: dots_container,
          margin: 40,
          navText: ['<img loading="lazy" src="/wp-content/themes/AmplifyChange2023/assets/img/icons/arrow-left.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/AmplifyChange2023/assets/img/icons/arrow-right.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            align_carousel_posts();
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onResized: function () {
            align_carousel_posts();
          },
          onResize: function () {
            align_carousel_posts();
          },
          onRefresh: function () {
            align_carousel_posts();
          },
          onRefreshed: function () {
            align_carousel_posts();
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
            0: {
              items: 1,
              margin: 0,
              dots: true
            },
            768: {
              items: 2,
              dots: true
            },
            1024: {
              items: 2,
              dots: true
            },
            1200: {
              items: items,
            },
            1366: {
              items: items
            }
          }
        });

      });

    }//end posts carousel


    //Cols carousel
    if ($('.cols-carousel').length) {

      let carousels = $('.cols-carousel'),
          max_items = 3;

      carousels.each(function(){

        let $this = $(this),
            items = $this.data('items-count'),
            nav_container = $this.data('dots-container');

        if (items > max_items) {
          items = max_items;
        }

        $this.owlCarousel({
          items: items,
          dots: true,
          nav: false,
          dotsContainer: nav_container,
          margin: 40,
          responsiveClass: true,
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          freeDrag: false,
          onInitialized: function (e) {
            align_collapsible_cols();
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onResized: function (e) {
            align_collapsible_cols();
          },
          onResize: function () {
            align_collapsible_cols();
          },
          onRefresh: function () {
            align_collapsible_cols();
          },
          onRefreshed: function () {
            align_collapsible_cols();
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
            0: {
              items: 1,
              margin: 20,
              touchDrag: true,
            },
            768: {
              items: (items < 2) ? items : 2,
              margin: 40
            },
            1024: {
              items: items,
            },
            1200: {
              items: items,
            },
            1366: {
              items: items
            }
          }
        });
      });

    }//end posts carousel


    //Grants boxes carousel
    if ($('.grant-boxes-carousel').length) {

      let carousels = $('.grant-boxes-carousel');
      carousels.each(function(){
        let $this = $(this);
        $this.owlCarousel({
          items: 1,
          dots: true,
          nav: false,
          margin: 0,
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
        });
      });

    }//end posts carousel


    //Grant box button click:
    $('button.grant-box-button').click(function(e){

      e.preventDefault();

      let $this = $(this),
          box_id = $this.data('box'),
          boxes = $('.grant-box');

      if ($this.hasClass('active')) {
        return false;
      }

      $('button.grant-box-button').removeClass('active');
      $this.addClass('active');
      boxes.removeClass('active');
      $(box_id).addClass('active');

      return false;

    });


    //Trigger the team modal:
    $('button.team-member-bio-btn').click(function(){

      const $this = $(this),
            name = $this.data('name'),
            bio = $this.data('bio'),
            img = $this.data('img'),
            role = $this.data('role');

      $('div#team-member-modal-img-wrap').html('<img src="'+img+'" alt="'+name+'">');
      $('#team-member-modal-content-wrap h2.modal-title').text(name+' - '+role);
      $('#team-member-modal-content-wrap div.inner').html(bio);

      $('#team-member-modal').modal('show');

    });


    //Open / close search
    $('button.search-btn').click(function(){

      const header_search_wrap = $('div#ampc-header-search'),
            search_input = $('input#header-search-input'),
            close_btn = $('button.header-search-close'),
            $this = $(this);

      if (header_search_wrap.hasClass('active')) {
        $this.attr('aria-expanded', 'false');
        close_btn.attr('aria-expanded', 'false');
        header_search_wrap.attr('aria-hidden', 'true');
        header_search_wrap.removeClass('active');
        setTimeout(function () {
          header_search_wrap.removeClass('show');
          search_input.blur();
        }, 550);
      } else {
        $this.attr('aria-expanded', 'true');
        close_btn.attr('aria-expanded', 'true');
        header_search_wrap.attr('aria-hidden', 'false');

        header_search_wrap.addClass('show');
        setTimeout(function () {
          header_search_wrap.addClass('active');
          search_input.focus();
        }, 50);
      }

    });//end open search


    //Close search
    $('button.header-search-close').click(function(){
      const header_search_wrap = $('div#ampc-header-search');

      header_search_wrap.removeClass('active').attr('aria-hidden', 'true');
      setTimeout(function () {
        header_search_wrap.removeClass('show');
      }, 550);
      $('input#header-search-input').blur();
      $('button.search-btn').attr('aria-expanded', 'false');
      $(this).attr('aria-expanded', 'false');
    });


    //Lang selector popup click:
    $('ul.ampc-lang-selector a').click(function(e){

      const $this = $(this),
            popup = $('.language-popup');

      //Set cookie
      set_cookie('selected-lang', true, 30);

      //Close or navigate
      if ($this.hasClass('active')) {
        e.preventDefault();
        popup.addClass('removing');
        setTimeout(function () {
          popup.remove();
        }, 550);
        return false;
      }

    });


    //Set carousel aria labels
    set_carousel_aria_labels();


    //Nav button toggles:
    $('#navbarCollapse').on('show.bs.collapse', function () {
      $('body').addClass('ofh');
    });

    $('#navbarCollapse').on('hide.bs.collapse', function () {
      $('body').removeClass('ofh');
    });


    //Set team modal bio inner scroll top on shown
    $('div#team-member-modal').on('shown.bs.modal', function(){
      $('#team-member-modal-content-wrap div.inner').scrollTop(0);
    });


    //Video modals
    $('button.video-iframe-loader').click(function(){

      let $this = $(this),
          iframe = $this.data('iframe'),
          holder = $('#video-modal-iframe-holder'),
          container = $('div.iframe-container');

      $('body').addClass('video-modal-open');

      holder.html(iframe);

      $('#video-modal').modal('show');

      //Wait for the iframe to fully load:
      let $iframe = holder.find('.ampc-video-iframe');
      $iframe.on('load', function() {
        container.removeClass('loading');
        setTimeout(function () {
          container.addClass('loaded');
        }, 500);
      });

    });


    //Clear the iframe html on closed to prevent the video from playing in the background
    $('#video-modal').on('hidden.bs.modal', function(){

      $('#video-modal-iframe-holder').html('');

    });


  });//end DOM loaded


})(jQuery); // Fully reference jQuery after this point.
